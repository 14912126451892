<template>
  <b-card>
    <!-- Modal edit item -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Name Input -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="nameInput">Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="nameInput"
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name"
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true, min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Description -->
          <b-col cols="12">
            <b-form-group id="input-description">
              <label for="descriptionInput">Ddescription:</label>
              <b-form-input
                id="descriptionInput"
                name="description"
                v-model="editedItem.description"
                type="text"
                aria-describedby="input-description"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Parent -->
          <b-col cols="12">
            <b-form-group id="input-parent">
              <label for="parentInput">Parent:</label>
              <b-form-input
                id="parentInput"
                name="parent"
                v-model="editedItem.parent"
                type="number"
                aria-describedby="input-parent"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Modal set role privilege -->
    <b-modal
      v-model="privilegeModal"
      size="lg"
      :title="privilegeTitle"
      ok-title="Save"
      header-close-content=""
      @ok="savePrivilege"
      @cancel="closePrivilege"
      scroll
    >
      <b-container fluid>
        <b-row>
          <b-col
            v-for="privilege in Object.keys(privilegeList)"
            cols="12"
            :key="'role_' + privilege"
            class="privilege-row"
          >
            <div v-if="privilegeList[privilege].actions" class="privilege-section">
              <div class="privilege-title">
                <h6>{{ privilegeList[privilege].title }}</h6>
                <b-form-checkbox
                  v-model="userPrivilege[privilege]"
                  @change="changeUserPrivilege(privilege)"
                ></b-form-checkbox>
              </div>
              <div
                v-for="action in Object.keys(privilegeList[privilege].actions)"
                :key="'role_' + privilege + '_' + action"
                class="privilege-action"
              >
                <label :for="'priv_' + privilege + '_' + action">{{ privilegeList[privilege].actions[action] }}</label>
                <b-form-checkbox
                  v-model="userPrivilege[`${privilege}.${action}`]"
                  @change="changeUserAction(privilege)"
                  :id="'priv_' + privilege + '_' + action"
                ></b-form-checkbox>
              </div>
            </div>
            <div v-else class="privilege-full">
              <h6>{{ privilegeList[privilege].title }}</h6>
              <b-form-checkbox v-model="userPrivilege[privilege]"></b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Role List</h4>
      </b-col>
      <!-- FilterName -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Role" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search roles name..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterActive -->
      <b-col cols="2">
        <b-form-group id="status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="6" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <span class="action-btn mr-2" @click="setRole(item.item)" :id="'role_' + item.item.id"
          ><i class="flaticon2-gear text-primary icon-md"></i>
        </span>
        <span class="action-btn mr-2" @click="editItem(item.item)" :id="'edit_' + item.item.id"
          ><i class="flaticon-edit text-success icon-md"></i>
        </span>
        <span class="action-btn mr-2" @click="deleteItem(item.item)" :id="'delete_' + item.item.id"
          ><i class="flaticon2-trash text-danger icon-md"></i>
        </span>
        <b-tooltip :target="'role_' + item.item.id" triggers="hover">Set Privilege</b-tooltip>
        <b-tooltip :target="'edit_' + item.item.id" triggers="hover">Edit</b-tooltip>
        <b-tooltip :target="'delete_' + item.item.id" triggers="hover">Delete</b-tooltip>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline id="deposit-input-per-page" style="margin-right: 20px; margin-bottom: 0">
          <label for="per-page" class="mr-sm-2">Rows per page:</label>
          <b-form-select
            id="deposit-per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
const role = require('@/common/config/role.config.json');
export default {
  components: {},
  data() {
    return {
      search: '',
      isBusy: false,
      filter_name: '',
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Name', key: 'name' },
        { text: 'Handler', key: 'handler' },
        { text: 'Description', key: 'description' },
        { text: 'Parent', key: 'parent' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-right' }
      ],
      // Modal data
      items: [],
      modalTitle: '',
      privilegeTitle: '',
      //Pagination
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        handler: '',
        description: '',
        parent: '',
        status: ''
      },
      dialog: false,
      privilegeModal: false,
      editedIndex: -1,
      privilegeList: {},
      userPrivilege: {},
      editedRole: {},
      editedRoleIndex: -1
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    privilegeModal(val) {
      val || this.closePrivilege();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return this.errors.has('name') || !this.editedItem.name;
    },
    user() {
      return this.$store.getters.currentUser.data;
    }
  },
  created() {
    this.initRole();
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Roles' }]);
  },
  methods: {
    initRole() {
      const privilege = {};
      for (let r of Object.keys(role)) {
        privilege[r] = false;
        if (role[r].actions) for (let a of Object.keys(role[r].actions)) privilege[`${r}.${a}`] = false;
      }
      this.userPrivilege = { ...privilege };
      this.privilegeList = { ...role };
    },
    mapUserRoleForView(rolePrivilege) {
      if (!rolePrivilege) return;
      const userRole = [...rolePrivilege];
      const privilege = this.userPrivilege;
      if (userRole.includes('*')) {
        Object.keys(privilege).forEach((k) => (privilege[k] = true));
      } else {
        for (let key of userRole) {
          privilege[key] = true;
        }
      }
      this.userPrivilege = { ...privilege };
    },
    async changeUserPrivilege(privilege) {
      await this.$nextTick();
      if (this.privilegeList[privilege].actions) {
        for (const key of Object.keys(this.privilegeList[privilege].actions)) {
          this.userPrivilege = { ...this.userPrivilege, [`${privilege}.${key}`]: this.userPrivilege[privilege] };
        }
      }
    },
    async changeUserAction(privilege) {
      await this.$nextTick();
      this.userPrivilege = {
        ...this.userPrivilege,
        [privilege]: Object.keys(this.privilegeList[privilege].actions).every(
          (key) => this.userPrivilege[`${privilege}.${key}`]
        )
      };
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const rolesRes = ApiService.get(
        'roles',
        `?name=${this.filter_name}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      );
      axios
        .all([rolesRes])
        .then(
          axios.spread((...response) => {
            const rolesRes = response[0];
            this.items = rolesRes.data.data.roleList.map((ads) => {
              return {
                ...ads,
                privilege: JSON.parse(ads.privilege)
              };
            });
            this.rows = rolesRes.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          if (errors) {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            this.isBusy = false;
          }
        });
    },
    addItem: function () {
      this.dialog = true;
      this.modalTitle = 'Add Roles';
    },
    editItem: function (item) {
      this.dialog = true;
      this.modalTitle = 'Update Roles';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.filter_name = item.name;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`roles/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Roles deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        name: '',
        handler: '',
        description: '',
        parent: '',
        status: ''
      };
      this.editedIndex = -1;
      this.invalidSlotCountMessage = '';
      this.filter_name = null;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Get new brand/cate/vendor name for data-table
      let productData = {
        name: this.editedItem.name,
        parent: +parseFloat(this.editedItem.parent).toFixed(2) || undefined,
        status: parseInt(this.filter_status),
        description: this.editedItem.description || undefined
      };
      if (this.editedIndex > -1) {
        // Update roles
        axios({
          method: 'PUT',
          url: `roles/${this.items[this.editedIndex].id}`,
          data: productData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              this.editedItem.name = this.filter_name;
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Roles updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        // Add role
        axios({
          method: 'POST',
          url: 'roles',
          data: productData,
          headers: { 'Content-Type': 'application/json' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data
              });
              this.close();
              this.$bvToast.toast('Roles added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    },
    setRole(role) {
      this.initRole();
      this.mapUserRoleForView(role.privilege);
      this.editedRole = { ...role };
      this.editedRoleIndex = this.items.indexOf(role);
      this.privilegeTitle = `Set ${role.name}'s Privileges`;
      this.privilegeModal = true;
    },
    closePrivilege() {
      this.privilegeModal = false;
      this.editedRole = {};
      this.editedRoleIndex = -1;
    },
    savePrivilege(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (Object.keys(role).every((key) => this.userPrivilege[key])) this.userPrivilege = ['*'];
      const privilege = Object.keys(this.userPrivilege).filter((key) => this.userPrivilege[key]);
      axios({
        method: 'PUT',
        url: `roles/${this.editedRole.id}/privileges`,
        data: { privilege }
      })
        .then((response) => {
          if (response.status === 204) {
            this.editedRole.privilege = privilege;
            Object.assign(this.items[this.editedRoleIndex], this.editedRole);
            this.$bvToast.toast(`${this.editedRole.name}'s privilege updated successfully`, {
              title: null,
              variant: 'success',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            this.closePrivilege();
          } else {
            this.errors.push(response.data.data);
          }
        })
        .catch((errors) => {
          if (errors) {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          }
        });
    }
  }
};
</script>
